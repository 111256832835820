<template>
    <app-wrapper>
        <template v-slot:child>
            <template v-if="isLoading"><Spinner /></template>

            <div class="w-100-l w-90 center pv4-l pv3 mini-spacing">
                <div class="flex flex-wrap justify-between w-90 center">
                    
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { onBeforeMount, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import AppWrapper from '@/layout/AppWrapper'
import Spinner from '@/components/Spinner'

export default {
    name: 'InvoiceView',
    components: {
        AppWrapper,
        Spinner,
    },

    setup() {
        const router = useRouter()
        const route = useRoute()
        const store = useStore()

        const isLoading = computed(() => store?.state.Invoice.isLoading)

        const initialState = {
            isLoading: true,
        }

        const state = reactive(initialState)

        onBeforeMount(() => {
            store.dispatch('Invoice/markAsPaid', route.params.id)
        })

        return {
            isLoading,
        }
    }
}
</script>

<style scoped>
.directional-up {
    width: 15px;
    height: 15px;
}
.sort1 {
    padding: 6px;
    cursor: pointer;
}
.sort1:hover {
    background-color: rgb(237, 240, 253);
    color: #132c8c;
    border-radius: 5px;
}
@media (max-width: 765px) {
    .flex.bill-section {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}
.org-img-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(215, 222, 250);
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 25px;
    color: rgb(19, 44, 140);
    margin-right: 5px;
}
.invoice-status-badge {
    /* background: #FEEAD7;  */
    /* color: #FB8B24;  */
    border-radius: 20px;
}
.share-link-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.share-btn-mobile {
    display: none;
}
.share-btn-desktop {
    display: inline;
}
@media screen and (max-width: 1261px) {
    .share-btn-mobile {
        display: inline;
    }
    .share-btn-desktop {
        display: none;
    }
}
.copy-text-wrapper button {
    border: none !important;
    outline: none !important;
    border-radius: 10px;
    cursor: pointer;
    background-color: unset !important;
    color: unset !important;
}

.copy-text-wrapper button:active {
    background: #809ce2;
}
.copy-text-wrapper button:before {
    content: 'Copied';
    position: absolute;
    top: -45px;
    right: 0px;
    background: #5c81dc;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: none;
}
.copy-text-wrapper button:after {
    content: '';
    position: absolute;
    top: -20px;
    right: 25px;
    width: 10px;
    height: 10px;
    background: #5c81dc;
    transform: rotate(45deg);
    display: none;
}
.copy-text-wrapper.active button:before,
.copy-text-wrapper.active button:after {
    display: block;
}
</style>
